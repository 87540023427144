// Axios encapsulation processing
import axios from "axios"
import { getToken, removeToken } from "./token"
import router from "@/router"
// 1. Root domain name configuration
// 2. Timeout
// 3. Request Interceptor / Response Interceptor

// const request = axios.create({
//   baseURL: 'https://geek.itheima.net/v1_0',
//   timeout: 5000
// })
// Create an Axios instance
const request = axios.create({
  baseURL: 'https://dc28.job-bid.ca/server/api', // Adjust to your local PHP backend URL
  timeout: 5000,
  withCredentials: true // Enable sending cookies with requests
})

// Add a request interceptor
// Before the request is sent, intercept and insert some custom configuration [parameter processing]
request.interceptors.request.use((config) => {
  // Operate this config to inject token data
  // 1. Get token
  // 2. Token splicing is done according to the format requirements of the backend
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// Adding a response interceptor
// Before the response is returned to the client, intercept and focus on processing the returned data
request.interceptors.response.use((response) => {
  // 2xx Status codes within the range will trigger this function.
  // Do something with the response data
  return response.data
}, (error) => {
  // Any status code outside the 2xx range will trigger this function.
  // Do something in response to the error
  // Monitor 401 token expiration
  console.dir(error)
  if (error.response.status === 401) {
    removeToken()
    router.navigate('/login')
    window.location.reload()
  }
  return Promise.reject(error)
})

export { request }



