// Routing Configuration

import Layout from '@/pages/Layout' // src/pages/layout
import Login from '@/pages/Login'

import { createBrowserRouter } from 'react-router-dom'
import { AuthRoute } from '@/components/AuthRoute'
// import Home from '@/pages/Home'
// import Article from '@/pages/Article'
// import Publish from '@/pages/Publish'
import { Suspense, lazy } from 'react'
// import Jobbid from '@/pages/Jobbid'

// 1. lazy function imports the component
const Home = lazy(() => import('@/pages/Home'))
const Article = lazy(() => import('@/pages/Article'))
const Publish = lazy(() => import('@/pages/Publish'))
const Jobbid = lazy(() => import('@/pages/Jobbid'))
const Users = lazy(() => import('@/pages/Users'));

// Configuring a Routing Instance

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthRoute> <Layout /></AuthRoute>,
    children: [
      {
        index: true,
        element: <Suspense fallback={'Loading'}><Home /></Suspense>
      },
      {
        path: 'article',
        element: <Suspense fallback={'Loading'}><Article /></Suspense>
      },
      {
        path: 'publish',
        element: <Suspense fallback={'Loading'}><Publish /></Suspense>
      },
      {
        path: 'users', // Add this line for user management
        element: <Suspense fallback={'Loading'}><Users /></Suspense>
      }
    ]
  },
  {
    path: 'Jobbid',
    element: <Suspense fallback={'Loading'}><Jobbid /></Suspense>
  },
  {
    path: "/login",
    element: <Login />
  }
])

export default router