// Combine redux submodules + export store instance

import { configureStore } from '@reduxjs/toolkit'
import userReducer from './modules/user'
import foodsReducer from './modules/foodsReducer'

const store = configureStore({
  reducer: {
    user: userReducer,
    foods: foodsReducer
  }
});

export default store;