import './index.scss'
import { Card, Form, Input, Button, message, Typography } from 'antd'
import logo from '@/assets/AspectLogo.svg'
import { useDispatch } from 'react-redux'
import { fetchLogin } from '@/store/modules/user'
import { useNavigate } from 'react-router-dom'

//Destructuring Typography Components:
const { Paragraph, Text } = Typography

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onFinish = async (values) => {
    console.log(values)
    // //Trigger asynchronous action fetchLogin
    // await dispatch(fetchLogin(values))
    // // 1. Skip to home page
    // //navigate('/')
    // // 2. Prompt the user
    // message.success('Login successful')


    try {
      // Await fetchLogin and handle success
      await dispatch(fetchLogin(values)); 
      message.success('Login successful');
      navigate('/'); // Redirect to the home page on success
    } catch (error) {
      // Show error message on failure
      message.error(error.response?.data?.message || 'Login failed');
    }

    // Trigger asynchronous action fetchLogin
    // const result = await dispatch(fetchLogin(values))

    // // Assuming result contains success status, handle it accordingly:
    // if (result.payload.success) {
    //   // 1. Skip to home page
    //   navigate('/')
    //   // 2. Prompt the user
    //   message.success('Login successful')
    // } else {
    //   // If login fails, display the error message from server response
    //   message.error(result.payload.message || 'Login failed. Please check your credentials.')
    // }
    
  }
  return (
    <div className="login">
      <Card className="login-container">
        <img className="login-logo" src={logo} alt="" />
        {/* Login Form */}
        <Form onFinish={onFinish} validateTrigger="onBlur">
          <Form.Item
            name="mobile"
            // Multiple verification logics Verify the first one first Verify the second one after the first one passes
            rules={[
              {
                required: true,
                message: 'Please enter your phone number',
              },
              {
                pattern: /^1[3-9]\d{9}$/,
                message: 'Please enter the correct mobile phone number format'
              }
            ]}>
            <Input size="large" placeholder="Please enter your phone number" />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: 'Please enter the verification code',
              },
            ]}>
            <Input size="large" placeholder="Please enter the verification code" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" block>
              Login
            </Button>
          </Form.Item>

          {/* New Messages Added Below */}
          <div className="login-info">
            <Paragraph>
              <Text strong>Need Help? Please see your Senior Supervisor.</Text>
            </Paragraph>
            <Paragraph>
              <Text strong>BID OPENS:</Text> WEDNESDAY OCT 11, 2024
            </Paragraph>
            <Paragraph>
              <Text strong>BID ENDS: &nbsp;</Text> WEDNESDAY OCT 30, 2024
            </Paragraph>

            <Paragraph>
              <Text strong>
                <span className="highlight">30</span> Days Left
              </Text>
            </Paragraph>

          </div>


        </Form>
      </Card>
    </div>
  )
}

export default Login